@import "./mixins";

// Raleway
@include font-face("Raleway", "./fonts/Raleway/RalewayBlack", 900, normal);

@include font-face("Raleway", "./fonts/Raleway/Raleway-Bold", 700, normal);

@include font-face("Raleway", "./fonts/Raleway/Raleway-Medium", 500, normal);

@include font-face("Raleway", "./fonts/Raleway/Raleway-Regular", 400, normal);

@include font-face("Raleway", "./fonts/Raleway/Raleway-Light", 300, normal);


// Proxima Nova

@include font-face("Proxima Nova", "./fonts/ProximaNova/ProximaNova-Bold", 600, normal);
@include font-face("Proxima Nova", "./fonts/ProximaNova/ProximaNova-BoldIt", 600, italic);

@include font-face("Proxima Nova", "./fonts/ProximaNova/ProximaNova-Semibold", 600, normal);
@include font-face("Proxima Nova", "./fonts/ProximaNova/ProximaNova-SemiboldIt", 600, italic);

@include font-face("Proxima Nova", "./fonts/ProximaNova/ProximaNova-Regular", 400, normal);
@include font-face("Proxima Nova", "./fonts/ProximaNova/ProximaNova-RegularIt", 400, italic);


// Proxima Nova Cond

@include font-face("Proxima Nova Condensed", "./fonts/ProximaNova/ProximaNovaCond-Bold", 600, normal);
@include font-face("Proxima Nova Condensed", "./fonts/ProximaNova/ProximaNovaCond-BoldIt", 600, italic);