.advantages {
  padding: 35px 0 64px;
  @include respond-below($responsive-xl) {
    padding: 30px 0 53px;
  }
  @include respond-below($responsive-lg) {
    padding: 30px 0 15px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &-list {
    display: flex;
    justify-content: space-between;
    @include respond-below($responsive-lg) {
      display: grid;
      grid-row-gap: 30px;
      grid-column-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }
    &__item {
      display: flex;
      max-width: 205px;
      flex-direction: column;
    }
    &__num {
      line-height: 1;
      margin-bottom: 15px;
      font-size: rem(46px);
      letter-spacing: .04em;
      @include respond-below($responsive-lg) {
        font-size: rem(40px);
      }
    }
    &__label {
      line-height: 1.2;
      padding-top: 9px;
      font-size: rem(16px);
      border-top: 6px solid $primary-color;
    }
  }
}