.faqs-side {
  padding: 100px 0 150px;
  @include respond-below($responsive-xl) {
    padding: 75px 0 60px;
  }
  @include respond-below($responsive-lg) {
    padding: 30px 0 40px;
  }
  &__wrapper {
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    @include respond-below($responsive-lg) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
  }
  &__item {
    @include respond-below($responsive-lg) {
      border-bottom: 1px solid #ccc;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &_active {
      .faqs-side__item-header {
        font-weight: 700;
        &:hover {
          color: $primary-text;
        }
        &:after {
          content: '';
          width: 42px;
          height: 16px;
          display: block;
          margin-left: 15px;
          background-image: url('../img/faqs-side/arrow.png');
          @include respond-below($responsive-lg) {
            content: unset;
          }
        }
        &:before {
          @include respond-below($responsive-lg) {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
    &-header {
      display: flex;
      cursor: pointer;
      position: relative;
      align-items: center;
      font-size: rem(20px);
      transition: color .3s ease-in-out;
      @include respond-below($responsive-lg) {
        line-height: 1;
        margin-bottom: 20px;
      }
      &:hover {
        color: $primary-color;
      }
      &:before {
        @include respond-below($responsive-lg) {
          top: 50%;
          right: 0;
          content: '';
          width: 20px;
          height: 20px;
          display: block;
          position: absolute;
          transform: translateY(-50%);
          transition: transform .3s ease-in-out;
          background-image: url('../img/faqs-side/carrot.png');
        }
      }
    }
    &-content {
      font-size: rem(30px);
      @include respond-below($responsive-lg) {
        font-size: rem(14px);
        margin-bottom: 20px;
      }
    }
    &-button {
      min-width: 197px;
      margin-top: 32px;
      @include respond-below($responsive-lg) {
        display: none;
      }
    }
  }
  &__content {
    .faqs-side__item-content {
      display: block !important;
      @include respond-below($responsive-lg) {
        display: none !important;
      }
    }
  }
}