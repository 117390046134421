.breadcrumbs {
  padding: 15px 0 36px;
  @include respond-below($responsive-lg) {
    display: none;
  }
  &__items {
    display: flex;
    align-items: center;
  }
  a.breadcrumbs__item {
    &:hover {
      color: $primary-color;
    }
  }
  &__item {
    color: $primary-text;
    font-size: rem(15px);
    &:not(:last-child) {
      &:after {
        content: '—';
        margin: 0 5px;
        color: $primary-text !important;
      }
    }
    &_active {
      color: #999;
    }
  }
}