.header {
  top: 0;
  left: 50%;
  width: 100%;
  z-index: 100;
  padding: 50px 0;
  position: fixed;
  max-width: 1920px;
  transform: translateX(-50%);
  transition: border .3s ease-in-out;
  border-bottom: 1px solid transparent;
  background-color: $primary-background;
  @include respond-below($responsive-xl) {
    padding: 33px 0;
  }
  &_fixed {
    border-color: #ececec;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond-below($responsive-xl) {
      align-items: flex-start;
    }
    @include respond-below($responsive-lg) {
      align-items: center;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    @include respond-below($responsive-xl) {
      &_last {
        align-items: flex-end;
        flex-direction: column-reverse;
      }
    }
  }
  &__phone {
    line-height: 1;
    font-weight: 700;
    margin-left: 20px;
    font-size: rem(15px);
    color: $primary-text;
    &:hover { color: $primary-color; }
    @include respond-below($responsive-xl) {
      margin-left: 0;
      margin-bottom: 20px;
    }
    @include respond-below($responsive-lg) {
      display: none;
    }
  }
  &__toggle {
    padding: 0;
    border: none;
    display: none;
    @include size(20px);
    background-color: transparent;
    @include respond-below($responsive-lg) {
      display: block;
    }
  }
  &-nav {
    display: flex;
    align-items: center;
    @include respond-below($responsive-lg) {
      display: none;
    }
    &__list {
      display: flex;
      align-items: center;
    }
    &__item {
      display: block;
      position: relative;
      &:hover {
        & > .header-nav__link {
          color: $primary-color
        }
        & > .header-dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
      &:not(:last-child) { margin-right: 20px; }
      @include last(2) {
        & > .header-dropdown {
          right: 0 !important;
          left: unset !important;
          .header-dropdown {
            &__item {
              & > .header-dropdown {
                left: unset !important;
                right: 100% !important;
              }
            }
          }
        }
      }
    }
    &__link {
      line-height: 1;
      position: relative;
      font-size: rem(15px);
      white-space: nowrap;
      color: $primary-text;
    }
  }
  &-dropdown {
    left: 0;
    top: 100%;
    opacity: 0;
    z-index: 100;
    display: block;
    min-width: 225px;
    padding-top: 10px;
    visibility: hidden;
    position: absolute;
    transition: opacity .15s linear;
    &__list {
      padding: 5px 0;
      overflow-y: auto;
      max-height: 50vh;
      border-radius: 2px;
      background-color: #fff;
      box-shadow: 2px 2px 12px #E7E3EE;
    }
    &__item {
      & > .header-dropdown {
        opacity: 0;
        z-index: 50;
        top: -9999px;
        left: -999px;
        margin-top: 0;
        display: block;
        visibility: hidden;
        .header-dropdown__link {
          &:before {
            opacity: 0 !important;
          }
        }
      }
      &:hover {
        & > .header-dropdown__link {
          background-color: $secondary-background;
          &:before {
            opacity: 1;
          }
        }
        & > .header-dropdown {
          top: 0;
          left: 100%;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &__link {
      display: block;
      line-height: 14px;
      position: relative;
      white-space: nowrap;
      color: $primary-text;
      font-size: rem(14px);
      padding: 10px 20px 10px 26px;
      transition: background-color .15s linear;
      &:before {
        top: 0;
        left: 0;
        opacity: 0;
        width: 4px;
        content: '';
        height: 100%;
        display: block;
        position: absolute;
        background-color: $primary-color;
        transition: opacity .15s linear;
      }
    }
  }
}