.info {
  padding: 36px 0 48px;
  @include respond-below($responsive-xl) {
    padding: 21px 0 40px;
  }
  @include respond-below($responsive-lg) {
    padding: 20px 0;
  }
  &_card {
    padding: 0 !important;
    .container {
      @include respond-below($responsive-lg) {
        padding: 0 !important;
      }
    }
  }
  &_section {
    margin: 48px 0 34px;
    padding: 56px 0 85px;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    @include respond-below($responsive-xl) {
      margin: 37px 0 25px;
      padding: 54px 0 70px;
    }
    @include respond-below($responsive-lg) {
      margin: 30px 0;
      padding: 42px 0;
    }
  }
  &__grid {
    display: grid;
    grid-gap: 40px;
    &_two {
      grid-template-columns: repeat(2, 1fr);
      p {
        margin-bottom: 18px;
        @include respond-below($responsive-lg) {
          margin-bottom: 0;
          font-size: rem(16px);
        }
      }
      &-fs {
        grid-template-columns: 1fr 1.5fr;
      }
      &-ls {
        grid-template-columns: 1.5fr 1fr;
      }
      @include respond-below($responsive-lg) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &_three {
      grid-template-columns: repeat(3, 1fr);
      @include respond-below($responsive-lg) {
        grid-template-columns: repeat(1, 1fr);
      }
      .info__subtitle {
        height: auto !important;
      }
    }
  }
  &__cell {
    display: flex;
    flex-direction: column;
  }
  &__title {
    line-height: 1;
    position: relative;
    margin-bottom: 20px;
    &_underline {
      padding-bottom: 30px;
      &:before {
        left: 0;
        bottom: 0;
        height: 6px;
        content: '';
        width: 211px;
        display: block;
        position: absolute;
        background-color: $primary-color;
      }
    }
  }
  &__subtitle {
    height: 50px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: rem(25px);
    @include respond-below($responsive-lg) {
      height: auto;
      font-size: rem(20px);
    }
  }
  p {
    font-size: rem(20px);
    @include respond-below($responsive-lg) {
      font-size: rem(16px);
    }
    &.mb60 {
      margin-bottom: 60px;
    }
    &.mb20 {
      margin-bottom: 20px;
    }
    &.boundary {
      max-width: 750px;
      @include respond-below($responsive-lg) {
        max-width: unset;
      }
    }
  }
  &__explanation {
    color: #9c9c9c;
    font-size: rem(14px);
  }
  &__image {
    width: 100%;
    max-width: 100%;
    margin: 50px 0 0;
  }
  ul {
    display: flex;
    flex-direction: column;
    li {
      position: relative;
      padding-left: 14px;
      &:before {
        @include size(6px);
        left: 0;
        top: 12px;
        content: '';
        display: block;
        position: absolute;
        background-color: $primary-color;
      }
      &:not(:last-of-type) {
        margin-bottom: 25px;
      }
    }
  }
  &-reference {
    display: flex;
    &_center {
      justify-content: center;
    }
    &__inner {
      display: flex;
      max-width: 267px;
      text-align: center;
      flex-direction: column;
    }
    &__num {
      line-height: 1;
      margin-bottom: 8px;
      font-size: rem(100px);
      color: $secondary-color;
    }
    p {
      color: #999;
      font-size: rem(20px);
    }
  }
  &-blocks {
    &__items {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
      @include respond-below($responsive-lg) {
        grid-template-columns: 1fr;
      }
    }
    &__item {
      display: flex;
      min-height: 480px;
      position: relative;
      background-size: cover;
      background-position: center;
      @include respond-below($responsive-xl) {
        min-height: 403px;
      }
      @include respond-below($responsive-lg) {
        min-height: 220px;
      }
      &_video {
        .info-blocks__title {
          font-size: rem(20px);
        }
      }
    }
    &__overlay {
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(#0057FF, .8);
    }
    &__title {
      z-index: 2;
      left: 38px;
      color: #fff;
      bottom: 38px;
      display: flex;
      line-height: 1;
      font-weight: 700;
      max-width: 350px;
      position: absolute;
      align-items: center;
      font-size: rem(30px);
      @include respond-below($responsive-lg) {
        left: 22px;
        bottom: 36px;
        font-size: rem(20px) !important;
      }
      svg {
        margin-right: 22px;
        @include size(43px);
      }
    }
  }
  &-card {
    display: flex;
    background: #fff;
    margin: 39px 0 34px;
    padding: 49px 31px 64px 54px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
    @include respond-below($responsive-xl) {
      margin: 15px 0 43px;
    }
    @include respond-below($responsive-lg) {
      margin: 15px 0 25px;
      padding: 33px 15px 38px;
    }
    &__block {
      &_first {
        width: 100%;
        display: grid;
        flex: 0 1 100%;
        grid-row-gap: 30px;
        grid-column-gap: 20px;
        grid-template-columns: repeat(3, 1fr);
        @include respond-below($responsive-md) {
          grid-row-gap: 20px;
          grid-template-columns: 1fr;
        }
      }
      &_last {
        width: 198px;
        display: flex;
        flex: 0 0 198px;
        margin: 0 0 0 38px;
        position: relative;
        @include respond-below($responsive-lg) {
          display: none;
        }
        &:before {
          top: -15px;
          z-index: 1;
          left: 30px;
          right: 30px;
          content: '';
          bottom: -15px;
          background: #fff;
          position: absolute;
          box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
        }
      }
    }
    &__cell {
      grid-column-start: span 1;
      &_big {
        grid-column-start: span 3;
        @include respond-below($responsive-md) {
          grid-column-start: span 1;
        }
      }
    }
    &__title {
      font-weight: 700;
      margin-bottom: 10px;
      font-size: rem(20px);
      & ~ p {
        font-size: rem(16px);
      }
    }
    &__subtitle {
      font-weight: 700;
      font-size: rem(20px);
      @include respond-below($responsive-lg) {
        font-size: rem(16px);
      }
      & ~ p {
        font-size: rem(14px);
      }
    }
    &__icon {
      z-index: 2;
      display: block;
      background: #fff;
      align-self: center;
      padding: 68px 68px;
      box-shadow: 0 0 50px rgba(0, 0, 0, .12);
      &-image {
        @include size(62px);
      }
    }
  }
}