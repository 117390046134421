.article {
  padding: 69px 0 46px;
  @include respond-below($responsive-xl) {
    padding: 40px 0 25px;
  }
  @include respond-below($responsive-lg) {
    display: none;
  }
  &__wrapper {
    display: flex;
    padding: 53px 0 58px;
    justify-content: center;
    background-color: $primary-background;
    box-shadow: 0 0 50px rgba(0, 0, 0, .12);
  }
  &__footnote {
    top: 5px;
    left: 26px;
    line-height: 1;
    position: absolute;
    font-size: rem(98px);
    color: $secondary-color;
  }
  &__inner {
    z-index: 5;
    display: block;
    max-width: 1070px;
    padding: 40px 90px;
    position: relative;
    background-color: $primary-background;
    box-shadow: 0 0 50px rgba(0, 0, 0, .12);
    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      content: '';
      display: block;
      position: absolute;
      background-color: $primary-background;
      box-shadow: 0 0 50px rgba(0, 0, 0, .12);
    }
    &:after {
      left: 40px;
      top: -23px;
      right: 40px;
      z-index: -1;
      content: '';
      opacity: 0.5;
      bottom: -23px;
      display: block;
      position: absolute;
      background-color: $primary-background;
      box-shadow: 0 0 50px rgba(0, 0, 0, .12);
    }
  }
  &__label, &__footnote {
    z-index: 6;
    display: block;
  }
  &__label {
    position: relative;
    font-size: rem(30px);
  }
}