.open-day {
  padding: 92px 0 100px;
  @include respond-below($responsive-xl) {
    padding: 75px 0;
  }
  @include respond-below($responsive-lg) {
    padding: 25px 0 30px;
  }
  &__wrapper {
    z-index: 1;
    display: flex;
    margin-left: 57px;
    position: relative;
    padding: 75px 58px 60px;
    box-shadow: 0 0 50px rgba(0, 0, 0, .12);
    @include respond-below($responsive-lg) {
      margin-left: 0;
      align-items: center;
      padding: 40px 20px 50px;
      flex-direction: column-reverse;
    }
    &:after {
      top: -82px;
      z-index: -1;
      left: -57px;
      right: 62px;
      content: '';
      opacity: 0.5;
      bottom: -82px;
      display: block;
      position: absolute;
      box-shadow: 0 0 50px rgba(0, 0, 0, .12);
      @include respond-below($responsive-lg) {
        content: unset;
      }
    }
    &:before {
      top: -35px;
      z-index: 1;
      content: '';
      right: 111px;
      width: 305px;
      opacity: 0.5;
      bottom: -35px;
      display: block;
      position: absolute;
      box-shadow: 0 0 50px rgba(0, 0, 0, .12);
      @include respond-below($responsive-lg) {
        content: unset;
      }
    }
  }
  &__block {
    &_first {
      display: block;
      max-width: 580px;
      position: relative;
      @include respond-below($responsive-lg) {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
    &_last {
      top: 50%;
      z-index: 5;
      right: 33px;
      padding: 55px 60px;
      position: absolute;
      transform: translateY(-50%);
      background-color: $primary-background;
      box-shadow: 0 0 50px rgba(0, 0, 0, .12);
      @include respond-below($responsive-lg) {
        padding: 0;
        box-shadow: none;
        position: static;
        transform: unset;
        margin-bottom: 26px;
      }
    }
  }
  &__title {
    line-height: 1;
    margin-bottom: 10px;
    @include respond-below($responsive-lg) {
      text-align: center;
    }
  }
  &__desc {
    margin-bottom: 30px;
    font-size: rem(20px);
    @include respond-below($responsive-lg) {
      text-align: center;
      margin-bottom: 27px;
      font-size: rem(16px);
    }
  }
}