.intro {
  padding: 6px 0 35px;
  @include respond-below($responsive-xl) {
    padding: 20px 0 30px;
  }
  @include respond-below($responsive-lg) {
    padding: 0 0 15px;
  }
  &__wrapper {
    display: flex;
    position: relative;
    @include respond-below($responsive-lg) {
      flex-direction: column-reverse;
    }
  }
  &__block {
    &_first {
      z-index: 2;
      display: block;
      position: relative;
    }
    &_last {
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      position: absolute;
      @include respond-below($responsive-lg) {
        position: relative;
      }
    }
  }
  &__title {
    display: flex;
    line-height: 1;
    margin-top: 50px;
    font-weight: 700;
    font-size: rem(80px);
    flex-direction: column;
    @include respond-below($responsive-xl) {
      margin-top: 27px;
      font-size: rem(70px);
    }
    @include respond-below($responsive-lg) {
      font-size: rem(44px);
    }
    span {
      line-height: 1;
      margin-top: 13px;
      font-size: rem(30px);
      @include respond-below($responsive-xl) {
        margin-top: 16px;
      }
      @include respond-below($responsive-lg) {
        margin-top: 9px;
        font-size: rem(22px);
      }
    }
  }
  &__desc {
    position: relative;
    margin: 55px 0 100px;
    font-size: rem(50px);
    @include respond-below($responsive-xl) {
      margin: 72px 0 60px;
    }
    @include respond-below($responsive-lg) {
      margin: 30px 0 0;
      font-size: rem(27px);
    }
    &:before {
      top: 0;
      left: 0;
      height: 5px;
      content: '';
      width: 167px;
      position: absolute;
      background-color: #E30014;
    }
  }
  &__image {
    max-height: 100%;
    @include respond-below($responsive-lg) {
      max-width: 100%;
    }
  }
}