.slider {
  padding: 48px 0;
  @include respond-below($responsive-xl) {
    padding: 40px 0;
  }
  @include respond-below($responsive-lg) {
    padding: 20px 0 30px;
  }
  &__image {
    width: 100%;
  }
  &__label {
    color: #9c9c9c;
    margin-top: 13px;
    font-size: rem(14px);
  }
  &-nav {
    display: flex;
    margin-top: 37px;
    align-items: center;
    justify-content: center;
    @include respond-below($responsive-lg) {
      padding: 0 15px;
      margin-top: 28px;
      justify-content: space-between;
    }
    &__button {
      cursor: pointer;
      padding: 17px 24px;
      border: 2px solid #c4c4c4;
      background-color: transparent;
    }
    &__num {
      width: 60px;
      display: flex;
      margin: 0 15px;
      align-items: center;
      justify-content: center;
      &-separator {
        margin: 0 5px;
      }
    }
  }
  .swiper-slide {
    opacity: .2;
    max-width: 1140px;
    transform: scale(0.9);
    transition: transform .3s, opacity .3s;
    @include respond-below($responsive-xl) {
      padding: 0 20px;
    }
    @include respond-below($responsive-lg) {
      padding: 0 15px;
    }
    &-active {
      opacity: 1;
      transform: scale(1);
    }
  }
}