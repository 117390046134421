@charset "UTF-8";
@font-face {
	font-family: "Raleway";
	font-weight: 900;
	font-style: normal;
	src: url("./fonts/Raleway/RalewayBlack.eot");
	src: url("./fonts/Raleway/RalewayBlack.eot?#iefix") format("embedded-opentype"), url("./fonts/Raleway/RalewayBlack.woff") format("woff"), url("./fonts/Raleway/RalewayBlack.ttf") format("truetype");
}

@font-face {
	font-family: "Raleway";
	font-weight: 700;
	font-style: normal;
	src: url("./fonts/Raleway/Raleway-Bold.eot");
	src: url("./fonts/Raleway/Raleway-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/Raleway/Raleway-Bold.woff") format("woff"), url("./fonts/Raleway/Raleway-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Raleway";
	font-weight: 500;
	font-style: normal;
	src: url("./fonts/Raleway/Raleway-Medium.eot");
	src: url("./fonts/Raleway/Raleway-Medium.eot?#iefix") format("embedded-opentype"), url("./fonts/Raleway/Raleway-Medium.woff") format("woff"), url("./fonts/Raleway/Raleway-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Raleway";
	font-weight: 400;
	font-style: normal;
	src: url("./fonts/Raleway/Raleway-Regular.eot");
	src: url("./fonts/Raleway/Raleway-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/Raleway/Raleway-Regular.woff") format("woff"), url("./fonts/Raleway/Raleway-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Raleway";
	font-weight: 300;
	font-style: normal;
	src: url("./fonts/Raleway/Raleway-Light.eot");
	src: url("./fonts/Raleway/Raleway-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/Raleway/Raleway-Light.woff") format("woff"), url("./fonts/Raleway/Raleway-Light.ttf") format("truetype");
}

@font-face {
	font-family: "Proxima Nova";
	font-weight: 600;
	font-style: normal;
	src: url("./fonts/ProximaNova/ProximaNova-Bold.eot");
	src: url("./fonts/ProximaNova/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova/ProximaNova-Bold.woff") format("woff"), url("./fonts/ProximaNova/ProximaNova-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Proxima Nova";
	font-weight: 600;
	font-style: italic;
	src: url("./fonts/ProximaNova/ProximaNova-BoldIt.eot");
	src: url("./fonts/ProximaNova/ProximaNova-BoldIt.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova/ProximaNova-BoldIt.woff") format("woff"), url("./fonts/ProximaNova/ProximaNova-BoldIt.ttf") format("truetype");
}

@font-face {
	font-family: "Proxima Nova";
	font-weight: 600;
	font-style: normal;
	src: url("./fonts/ProximaNova/ProximaNova-Semibold.eot");
	src: url("./fonts/ProximaNova/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova/ProximaNova-Semibold.woff") format("woff"), url("./fonts/ProximaNova/ProximaNova-Semibold.ttf") format("truetype");
}

@font-face {
	font-family: "Proxima Nova";
	font-weight: 600;
	font-style: italic;
	src: url("./fonts/ProximaNova/ProximaNova-SemiboldIt.eot");
	src: url("./fonts/ProximaNova/ProximaNova-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova/ProximaNova-SemiboldIt.woff") format("woff"), url("./fonts/ProximaNova/ProximaNova-SemiboldIt.ttf") format("truetype");
}

@font-face {
	font-family: "Proxima Nova";
	font-weight: 400;
	font-style: normal;
	src: url("./fonts/ProximaNova/ProximaNova-Regular.eot");
	src: url("./fonts/ProximaNova/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova/ProximaNova-Regular.woff") format("woff"), url("./fonts/ProximaNova/ProximaNova-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Proxima Nova";
	font-weight: 400;
	font-style: italic;
	src: url("./fonts/ProximaNova/ProximaNova-RegularIt.eot");
	src: url("./fonts/ProximaNova/ProximaNova-RegularIt.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova/ProximaNova-RegularIt.woff") format("woff"), url("./fonts/ProximaNova/ProximaNova-RegularIt.ttf") format("truetype");
}

@font-face {
	font-family: "Proxima Nova Condensed";
	font-weight: 600;
	font-style: normal;
	src: url("./fonts/ProximaNova/ProximaNovaCond-Bold.eot");
	src: url("./fonts/ProximaNova/ProximaNovaCond-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova/ProximaNovaCond-Bold.woff") format("woff"), url("./fonts/ProximaNova/ProximaNovaCond-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Proxima Nova Condensed";
	font-weight: 600;
	font-style: italic;
	src: url("./fonts/ProximaNova/ProximaNovaCond-BoldIt.eot");
	src: url("./fonts/ProximaNova/ProximaNovaCond-BoldIt.eot?#iefix") format("embedded-opentype"), url("./fonts/ProximaNova/ProximaNovaCond-BoldIt.woff") format("woff"), url("./fonts/ProximaNova/ProximaNovaCond-BoldIt.ttf") format("truetype");
}

html {
	cursor: default;
	font-weight: 400;
	letter-spacing: .01px;
	min-width: 360px;
	font-size: 18px;
	line-height: 1.5;
	font-family: "Raleway", Arial, sans-serif !important;
	font-variant-numeric: lining-nums;
	-webkit-text-size-adjust: 100%;
	font-variant-ligatures: no-common-ligatures;
}

body {
	margin: 0;
	min-height: 100vh;
	color: #000;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: 400;
}

ul, li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

a, a:hover {
	text-decoration: none;
}

a, .button {
	transition: color .3s ease-in-out;
}

p {
	margin: 0;
}

audio, canvas, iframe, img, svg, video, textarea {
	vertical-align: middle;
}

textarea {
	resize: none;
}

section {
	position: relative;
}

input, select, button {
	outline: none;
}

*, *:before, *:after {
	box-sizing: border-box;
}

.wrapper {
	width: 100%;
	display: flex;
	margin: 0 auto;
	max-width: 1920px;
	min-height: 100vh;
	padding-top: 127px;
	flex-direction: column;
	background-color: #fff;
	box-shadow: 0 0 21px rgba(157, 163, 183, 0.75);
}

@media screen and (max-width: 1199px) {
	.wrapper {
		padding-top: 123px;
	}
}

@media screen and (max-width: 991px) {
	.wrapper {
		padding-top: 93px;
	}
}

.container {
	width: 100%;
	margin: 0 auto;
	max-width: 1180px;
	padding: 0 20px;
}

@media screen and (max-width: 991px) {
	.container {
		padding: 0 15px;
	}
}

.container_aside-right {
	display: grid;
	grid-gap: 0 50px;
	grid-template-columns: 3fr 1fr;
}

@media screen and (max-width: 991px) {
	.container_aside-right {
		grid-template-columns: 1fr;
	}
}

.fancybox-privacy__content {
	max-width: 960px;
}

.title {
	font-weight: 700;
	font-size: 2.22222rem;
}

@media screen and (max-width: 991px) {
	.title {
		line-height: 1;
		font-size: 1.66667rem;
	}
}

.subtitle {
	font-weight: 700;
	font-size: 1.66667rem;
}

@media screen and (max-width: 991px) {
	.subtitle {
		line-height: 1.2;
		font-size: 1.44444rem;
	}
}

.logo {
	display: flex;
}

.logo.logo_header .logo__link {
	height: 26px;
}

.logo.logo_footer .logo__link {
	height: 34px;
}

.logo__link {
	display: flex;
}

.logo__image {
	height: 100%;
}

.button {
	padding: 20px;
	cursor: pointer;
	font-weight: 700;
	text-align: center;
	font-size: 0.88889rem;
	display: inline-block;
	border: 1px solid transparent;
	background-color: transparent;
	transition: all .3s ease-in-out;
}

.button_primary {
	color: #fff;
	border-color: #ff8329;
	background-color: #ff8329;
}

.button_primary:hover {
	opacity: .9;
}

.button_outline.button_secondary {
	color: #000;
	border-color: #0053f7;
	background-color: #fff;
}

.button_outline.button_secondary:hover {
	color: #fff;
	background-color: #0053f7;
}

.header {
	top: 0;
	left: 50%;
	width: 100%;
	z-index: 100;
	padding: 50px 0;
	position: fixed;
	max-width: 1920px;
	transform: translateX(-50%);
	transition: border .3s ease-in-out;
	border-bottom: 1px solid transparent;
	background-color: #fff;
}

@media screen and (max-width: 1199px) {
	.header {
		padding: 33px 0;
	}
}

.header_fixed {
	border-color: #ececec;
}

.header__wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media screen and (max-width: 1199px) {
	.header__wrapper {
		align-items: flex-start;
	}
}

@media screen and (max-width: 991px) {
	.header__wrapper {
		align-items: center;
	}
}

.header__block {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 1199px) {
	.header__block_last {
		align-items: flex-end;
		flex-direction: column-reverse;
	}
}

.header__phone {
	line-height: 1;
	font-weight: 700;
	margin-left: 20px;
	font-size: 0.83333rem;
	color: #000;
}

.header__phone:hover {
	color: #ff8329;
}

@media screen and (max-width: 1199px) {
	.header__phone {
		margin-left: 0;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 991px) {
	.header__phone {
		display: none;
	}
}

.header__toggle {
	padding: 0;
	border: none;
	display: none;
	width: 20px;
	height: 20px;
	background-color: transparent;
}

@media screen and (max-width: 991px) {
	.header__toggle {
		display: block;
	}
}

.header-nav {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 991px) {
	.header-nav {
		display: none;
	}
}

.header-nav__list {
	display: flex;
	align-items: center;
}

.header-nav__item {
	display: block;
	position: relative;
}

.header-nav__item:hover > .header-nav__link {
	color: #ff8329;
}

.header-nav__item:hover > .header-dropdown {
	opacity: 1;
	visibility: visible;
}

.header-nav__item:not(:last-child) {
	margin-right: 20px;
}

.header-nav__item:nth-last-child(-n + 2) > .header-dropdown {
	right: 0 !important;
	left: unset !important;
}

.header-nav__item:nth-last-child(-n + 2) > .header-dropdown .header-dropdown__item > .header-dropdown {
	left: unset !important;
	right: 100% !important;
}

.header-nav__link {
	line-height: 1;
	position: relative;
	font-size: 0.83333rem;
	white-space: nowrap;
	color: #000;
}

.header-dropdown {
	left: 0;
	top: 100%;
	opacity: 0;
	z-index: 100;
	display: block;
	min-width: 225px;
	padding-top: 10px;
	visibility: hidden;
	position: absolute;
	transition: opacity .15s linear;
}

.header-dropdown__list {
	padding: 5px 0;
	overflow-y: auto;
	max-height: 50vh;
	border-radius: 2px;
	background-color: #fff;
	box-shadow: 2px 2px 12px #E7E3EE;
}

.header-dropdown__item > .header-dropdown {
	opacity: 0;
	z-index: 50;
	top: -9999px;
	left: -999px;
	margin-top: 0;
	display: block;
	visibility: hidden;
}

.header-dropdown__item > .header-dropdown .header-dropdown__link:before {
	opacity: 0 !important;
}

.header-dropdown__item:hover > .header-dropdown__link {
	background-color: #f3f3f3;
}

.header-dropdown__item:hover > .header-dropdown__link:before {
	opacity: 1;
}

.header-dropdown__item:hover > .header-dropdown {
	top: 0;
	left: 100%;
	opacity: 1;
	visibility: visible;
}

.header-dropdown__link {
	display: block;
	line-height: 14px;
	position: relative;
	white-space: nowrap;
	color: #000;
	font-size: 0.77778rem;
	padding: 10px 20px 10px 26px;
	transition: background-color .15s linear;
}

.header-dropdown__link:before {
	top: 0;
	left: 0;
	opacity: 0;
	width: 4px;
	content: '';
	height: 100%;
	display: block;
	position: absolute;
	background-color: #ff8329;
	transition: opacity .15s linear;
}

.intro {
	padding: 6px 0 35px;
}

@media screen and (max-width: 1199px) {
	.intro {
		padding: 20px 0 30px;
	}
}

@media screen and (max-width: 991px) {
	.intro {
		padding: 0 0 15px;
	}
}

.intro__wrapper {
	display: flex;
	position: relative;
}

@media screen and (max-width: 991px) {
	.intro__wrapper {
		flex-direction: column-reverse;
	}
}

.intro__block_first {
	z-index: 2;
	display: block;
	position: relative;
}

.intro__block_last {
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	position: absolute;
}

@media screen and (max-width: 991px) {
	.intro__block_last {
		position: relative;
	}
}

.intro__title {
	display: flex;
	line-height: 1;
	margin-top: 50px;
	font-weight: 700;
	font-size: 4.44444rem;
	flex-direction: column;
}

@media screen and (max-width: 1199px) {
	.intro__title {
		margin-top: 27px;
		font-size: 3.88889rem;
	}
}

@media screen and (max-width: 991px) {
	.intro__title {
		font-size: 2.44444rem;
	}
}

.intro__title span {
	line-height: 1;
	margin-top: 13px;
	font-size: 1.66667rem;
}

@media screen and (max-width: 1199px) {
	.intro__title span {
		margin-top: 16px;
	}
}

@media screen and (max-width: 991px) {
	.intro__title span {
		margin-top: 9px;
		font-size: 1.22222rem;
	}
}

.intro__desc {
	position: relative;
	margin: 55px 0 100px;
	font-size: 2.77778rem;
}

@media screen and (max-width: 1199px) {
	.intro__desc {
		margin: 72px 0 60px;
	}
}

@media screen and (max-width: 991px) {
	.intro__desc {
		margin: 30px 0 0;
		font-size: 1.5rem;
	}
}

.intro__desc:before {
	top: 0;
	left: 0;
	height: 5px;
	content: '';
	width: 167px;
	position: absolute;
	background-color: #E30014;
}

.intro__image {
	max-height: 100%;
}

@media screen and (max-width: 991px) {
	.intro__image {
		max-width: 100%;
	}
}

.advantages {
	padding: 35px 0 64px;
}

@media screen and (max-width: 1199px) {
	.advantages {
		padding: 30px 0 53px;
	}
}

@media screen and (max-width: 991px) {
	.advantages {
		padding: 30px 0 15px;
	}
}

.advantages__wrapper {
	display: flex;
	flex-direction: column;
}

.advantages-list {
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width: 991px) {
	.advantages-list {
		display: grid;
		grid-row-gap: 30px;
		grid-column-gap: 20px;
		grid-template-columns: repeat(2, 1fr);
	}
}

.advantages-list__item {
	display: flex;
	max-width: 205px;
	flex-direction: column;
}

.advantages-list__num {
	line-height: 1;
	margin-bottom: 15px;
	font-size: 2.55556rem;
	letter-spacing: .04em;
}

@media screen and (max-width: 991px) {
	.advantages-list__num {
		font-size: 2.22222rem;
	}
}

.advantages-list__label {
	line-height: 1.2;
	padding-top: 9px;
	font-size: 0.88889rem;
	border-top: 6px solid #ff8329;
}

.catalog {
	padding: 64px 0 59px;
}

@media screen and (max-width: 1199px) {
	.catalog {
		padding: 53px 0 52px;
	}
}

@media screen and (max-width: 991px) {
	.catalog {
		padding: 24px 0;
	}
}

.catalog__wrapper {
	z-index: 6;
	display: flex;
	margin-left: 33px;
	position: relative;
	padding: 26px 64px;
	align-items: center;
}

@media screen and (max-width: 991px) {
	.catalog__wrapper {
		margin-left: 0;
		flex-direction: column;
		padding: 30px 20px 40px;
	}
}

.catalog__wrapper:before {
	z-index: 1;
	top: -44px;
	left: -33px;
	content: '';
	right: 290px;
	bottom: -44px;
	display: block;
	position: absolute;
	background-color: #fff;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 991px) {
	.catalog__wrapper:before {
		content: unset;
	}
}

.catalog__wrapper:after {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	content: '';
	display: block;
	position: absolute;
	background-color: #fff;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

.catalog__block {
	z-index: 9;
	display: block;
	position: relative;
}

.catalog__block_first {
	max-width: 628px;
}

@media screen and (max-width: 991px) {
	.catalog__block_first {
		margin-bottom: 25px;
	}
}

.catalog__block_last {
	margin-left: 80px;
}

@media screen and (max-width: 991px) {
	.catalog__block_last {
		margin-left: 0;
	}
}

.catalog__block_last:before {
	z-index: 2;
	top: -40px;
	content: '';
	left: -64px;
	right: -40px;
	bottom: -40px;
	opacity: 0.56;
	display: block;
	position: absolute;
	background-color: #fff;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 991px) {
	.catalog__block_last:before {
		content: unset;
	}
}

.catalog__title {
	margin-bottom: 8px;
}

@media screen and (max-width: 991px) {
	.catalog__title {
		text-align: center;
	}
}

.catalog__desc {
	font-size: 0.88889rem;
}

@media screen and (max-width: 991px) {
	.catalog__desc {
		text-align: center;
	}
}

.catalog__button {
	width: 198px;
	height: 198px;
	z-index: 3;
	color: #fff;
	display: flex;
	font-weight: 700;
	border-radius: 50%;
	position: relative;
	text-align: center;
	align-items: center;
	font-size: 1.11111rem;
	justify-content: center;
	background-color: #0053f7;
	transition: opacity .3s ease-in-out;
}

.catalog__button:hover {
	opacity: .9;
}

.cards {
	padding: 64px 0 92px;
}

@media screen and (max-width: 1199px) {
	.cards {
		padding: 50px 0 75px;
	}
}

@media screen and (max-width: 991px) {
	.cards {
		padding: 24px 0 25px;
	}
}

.cards__wrapper {
	display: flex;
	flex-direction: column;
}

.cards__title {
	margin-bottom: 50px;
}

@media screen and (max-width: 1199px) {
	.cards__title {
		margin-bottom: 22px;
	}
}

@media screen and (max-width: 991px) {
	.cards__title {
		line-height: 1.5;
		margin-bottom: 5px;
	}
}

.cards-list {
	display: grid;
	grid-row-gap: 58px;
	grid-column-gap: 20px;
	grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 1199px) {
	.cards-list {
		grid-row-gap: 50px;
	}
}

@media screen and (max-width: 991px) {
	.cards-list {
		grid-row-gap: 30px;
		grid-template-columns: repeat(1, 1fr);
	}
}

.cards-item {
	color: #000;
}

.cards-item__preview {
	width: 100%;
	height: 193px;
	border-radius: 5px;
	margin-bottom: 10px;
	background-size: cover;
	background-position: center;
}

.cards-item__title {
	font-size: 1.66667rem;
	transition: color .3s ease-in-out;
}

@media screen and (max-width: 1199px) {
	.cards-item__title {
		font-size: 1.44444rem;
	}
}

@media screen and (max-width: 991px) {
	.cards-item__title {
		font-size: 1.22222rem;
	}
}

.cards-item__desc {
	margin-top: 3px;
	font-size: 0.77778rem;
}

.open-day {
	padding: 92px 0 100px;
}

@media screen and (max-width: 1199px) {
	.open-day {
		padding: 75px 0;
	}
}

@media screen and (max-width: 991px) {
	.open-day {
		padding: 25px 0 30px;
	}
}

.open-day__wrapper {
	z-index: 1;
	display: flex;
	margin-left: 57px;
	position: relative;
	padding: 75px 58px 60px;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 991px) {
	.open-day__wrapper {
		margin-left: 0;
		align-items: center;
		padding: 40px 20px 50px;
		flex-direction: column-reverse;
	}
}

.open-day__wrapper:after {
	top: -82px;
	z-index: -1;
	left: -57px;
	right: 62px;
	content: '';
	opacity: 0.5;
	bottom: -82px;
	display: block;
	position: absolute;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 991px) {
	.open-day__wrapper:after {
		content: unset;
	}
}

.open-day__wrapper:before {
	top: -35px;
	z-index: 1;
	content: '';
	right: 111px;
	width: 305px;
	opacity: 0.5;
	bottom: -35px;
	display: block;
	position: absolute;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 991px) {
	.open-day__wrapper:before {
		content: unset;
	}
}

.open-day__block_first {
	display: block;
	max-width: 580px;
	position: relative;
}

@media screen and (max-width: 991px) {
	.open-day__block_first {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
}

.open-day__block_last {
	top: 50%;
	z-index: 5;
	right: 33px;
	padding: 55px 60px;
	position: absolute;
	transform: translateY(-50%);
	background-color: #fff;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 991px) {
	.open-day__block_last {
		padding: 0;
		box-shadow: none;
		position: static;
		transform: unset;
		margin-bottom: 26px;
	}
}

.open-day__title {
	line-height: 1;
	margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
	.open-day__title {
		text-align: center;
	}
}

.open-day__desc {
	margin-bottom: 30px;
	font-size: 1.11111rem;
}

@media screen and (max-width: 991px) {
	.open-day__desc {
		text-align: center;
		margin-bottom: 27px;
		font-size: 0.88889rem;
	}
}

.faqs-side {
	padding: 100px 0 150px;
}

@media screen and (max-width: 1199px) {
	.faqs-side {
		padding: 75px 0 60px;
	}
}

@media screen and (max-width: 991px) {
	.faqs-side {
		padding: 30px 0 40px;
	}
}

.faqs-side__wrapper {
	display: grid;
	grid-column-gap: 30px;
	grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 991px) {
	.faqs-side__wrapper {
		grid-template-columns: repeat(1, 1fr);
	}
}

.faqs-side__items {
	display: flex;
	flex-direction: column;
}

@media screen and (max-width: 991px) {
	.faqs-side__item {
		border-bottom: 1px solid #ccc;
	}
}

.faqs-side__item:not(:last-child) {
	margin-bottom: 20px;
}

.faqs-side__item_active .faqs-side__item-header {
	font-weight: 700;
}

.faqs-side__item_active .faqs-side__item-header:hover {
	color: #000;
}

.faqs-side__item_active .faqs-side__item-header:after {
	content: '';
	width: 42px;
	height: 16px;
	display: block;
	margin-left: 15px;
	background-image: url("../img/faqs-side/arrow.png");
}

@media screen and (max-width: 991px) {
	.faqs-side__item_active .faqs-side__item-header:after {
		content: unset;
	}
}

@media screen and (max-width: 991px) {
	.faqs-side__item_active .faqs-side__item-header:before {
		transform: translateY(-50%) rotate(180deg);
	}
}

.faqs-side__item-header {
	display: flex;
	cursor: pointer;
	position: relative;
	align-items: center;
	font-size: 1.11111rem;
	transition: color .3s ease-in-out;
}

@media screen and (max-width: 991px) {
	.faqs-side__item-header {
		line-height: 1;
		margin-bottom: 20px;
	}
}

.faqs-side__item-header:hover {
	color: #ff8329;
}

@media screen and (max-width: 991px) {
	.faqs-side__item-header:before {
		top: 50%;
		right: 0;
		content: '';
		width: 20px;
		height: 20px;
		display: block;
		position: absolute;
		transform: translateY(-50%);
		transition: transform .3s ease-in-out;
		background-image: url("../img/faqs-side/carrot.png");
	}
}

.faqs-side__item-content {
	font-size: 1.66667rem;
}

@media screen and (max-width: 991px) {
	.faqs-side__item-content {
		font-size: 0.77778rem;
		margin-bottom: 20px;
	}
}

.faqs-side__item-button {
	min-width: 197px;
	margin-top: 32px;
}

@media screen and (max-width: 991px) {
	.faqs-side__item-button {
		display: none;
	}
}

.faqs-side__content .faqs-side__item-content {
	display: block !important;
}

@media screen and (max-width: 991px) {
	.faqs-side__content .faqs-side__item-content {
		display: none !important;
	}
}

.section-form {
	padding: 80px 0 90px;
	background-color: #f3f3f3;
}

@media screen and (max-width: 1199px) {
	.section-form {
		padding: 35px 0 45px;
	}
}

@media screen and (max-width: 991px) {
	.section-form {
		padding: 40px 0 50px;
	}
}

.form {
	display: flex;
	flex-direction: column;
}

@media screen and (max-width: 991px) {
	.form__title {
		margin-bottom: 10px;
	}
}

.form__text {
	max-width: 80%;
	margin-bottom: 20px;
	font-size: 1.11111rem;
}

@media screen and (max-width: 991px) {
	.form__text {
		line-height: 1.2;
		max-width: unset;
		font-size: 1rem;
	}
}

.form__fields {
	display: flex;
	margin-bottom: 22px;
	align-items: flex-start;
}

@media screen and (max-width: 991px) {
	.form__fields {
		flex-direction: column;
	}
}

@media screen and (max-width: 991px) {
	.form__item {
		width: 100%;
	}
}

.form__item:not(:last-child) {
	margin-right: 15px;
}

@media screen and (max-width: 991px) {
	.form__item:not(:last-child) {
		margin-right: 0;
		margin-bottom: 10px;
	}
}

.form__input {
	padding: 20px;
	line-height: 1;
	min-width: 388px;
	font-size: 0.88889rem;
	border: 1px solid #e6e6e6;
	background-color: #fff;
}

@media screen and (max-width: 1199px) {
	.form__input {
		min-width: 338px;
	}
}

@media screen and (max-width: 991px) {
	.form__input {
		width: 100%;
		min-width: auto;
	}
}

.form__input ::-webkit-input-placeholder {
	opacity: .6;
	color: #000;
}

.form__input :-moz-placeholder {
	opacity: .6;
	color: #000;
}

.form__input ::-moz-placeholder {
	opacity: .6;
	color: #000;
}

.form__input :-ms-input-placeholder {
	opacity: .6;
	color: #000;
}

.form__item {
	display: flex;
	flex-direction: column;
}

.form__item label.error {
	color: #fe334a;
	font-size: 0.66667rem;
}

.form__footer {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 991px) {
	.form__footer {
		align-items: flex-start;
	}
}

.form__footer-checkbox {
	margin-right: 7px;
}

.form__footer-checkbox input {
	margin: 0;
}

.form__footer-icon {
	display: none;
}

.form__footer-text {
	font-size: 0.72222rem;
	text-transform: uppercase;
}

.form__footer-text a {
	color: #000;
}

.form__footer-text a:hover {
	color: #ff8329;
}

.faqs {
	padding: 80px 0 55px;
}

@media screen and (max-width: 1199px) {
	.faqs {
		padding: 55px 0 28px;
	}
}

@media screen and (max-width: 991px) {
	.faqs {
		padding: 33px 0 18px;
	}
}

.faqs__title {
	margin-bottom: 18px;
}

@media screen and (max-width: 991px) {
	.faqs__title {
		margin-bottom: 33px;
	}
}

.faqs__items {
	display: flex;
	flex-direction: column;
}

.faqs__item {
	border-radius: 4px;
	border-left: 9px solid #ff8329;
	box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.07);
}

@media screen and (max-width: 1199px) {
	.faqs__item {
		border-width: 8px;
	}
}

.faqs__item:not(:last-child) {
	margin-bottom: 15px;
}

@media screen and (max-width: 991px) {
	.faqs__item:not(:last-child) {
		margin-bottom: 10px;
	}
}

.faqs__item_active .faqs__header:after {
	transform: translateY(-50%) rotate(180deg);
}

.faqs__header {
	line-height: 1;
	cursor: pointer;
	font-weight: 700;
	padding: 32px 30px;
	position: relative;
	font-size: 1.61111rem;
}

.faqs__header:after {
	top: 50%;
	width: 8px;
	height: 5px;
	right: 23px;
	content: '';
	display: block;
	position: absolute;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	transform: translateY(-50%) rotate(0);
	transition: transform .3s ease-in-out;
	background-image: url("../img/faqs/carrot.png");
}

@media screen and (max-width: 1199px) {
	.faqs__header {
		padding: 25px 40px;
		font-size: 1.44444rem;
	}
}

@media screen and (max-width: 991px) {
	.faqs__header {
		font-size: 1rem;
		padding: 15px 20px;
	}
}

.faqs__content {
	padding: 40px 30px 30px;
	border-top: 1px solid #f3f3f3;
}

@media screen and (max-width: 991px) {
	.faqs__content {
		padding: 17px 13px 30px;
	}
}

.faqs__content ul {
	margin-bottom: 25px;
	font-size: 1.27778rem;
}

@media screen and (max-width: 991px) {
	.faqs__content ul {
		margin-bottom: 20px;
		font-size: 0.88889rem;
	}
}

.faqs__content ul li {
	display: flex;
	position: relative;
	align-items: flex-start;
}

.faqs__content ul li:before {
	width: 6px;
	height: 6px;
	content: '';
	margin-top: 14px;
	margin-right: 8px;
	position: relative;
	background-color: #ff8329;
}

.faqs__content ul li:not(:last-child) {
	margin-bottom: 20px;
}

.faqs__content ol {
	display: flex;
	flex-direction: column;
}

.faqs__content ol li {
	display: flex;
	line-height: 1.5;
	align-items: center;
	font-size: 1.27778rem;
}

.faqs__content ol li:not(:last-child) {
	margin-bottom: 50px;
}

@media screen and (max-width: 991px) {
	.faqs__content ol li:not(:last-child) {
		margin-bottom: 20px;
		font-size: 0.88889rem;
	}
}

.faqs__content ol__num {
	width: 23px;
	margin-right: 20px;
	font-size: 0.88889rem;
	color: #0053f7;
}

@media screen and (max-width: 991px) {
	.faqs__content ol__num {
		margin-right: 5px;
		text-align: center;
		font-size: 0.66667rem;
	}
}

.faqs__content > p {
	line-height: 1.5;
	font-size: 1.27778rem;
}

.faqs__content > p:not(:last-child) {
	margin-bottom: 25px;
}

@media screen and (max-width: 991px) {
	.faqs__content > p {
		font-size: 0.88889rem;
	}
	.faqs__content > p:not(:last-child) {
		margin-bottom: 20px;
	}
}

.faqs__footer {
	margin-top: 50px;
}

@media screen and (max-width: 991px) {
	.faqs__footer {
		margin-top: 26px;
	}
}

.faqs__button {
	min-width: 310px;
}

@media screen and (max-width: 991px) {
	.faqs__button {
		width: 100%;
	}
}

.contacts {
	padding: 55px 0 80px;
}

@media screen and (max-width: 1199px) {
	.contacts {
		padding: 28px 0 90px;
	}
}

@media screen and (max-width: 991px) {
	.contacts {
		padding: 18px 0 50px;
	}
}

.contacts__wrapper {
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width: 991px) {
	.contacts__wrapper {
		flex-direction: column;
		justify-content: flex-start;
	}
}

.contacts__block_first {
	margin-right: 80px;
}

@media screen and (max-width: 991px) {
	.contacts__block_first {
		margin-right: 0;
		margin-bottom: 40px;
	}
}

@media screen and (max-width: 575px) {
	.contacts__block_last {
		position: relative;
		padding-bottom: 85%;
	}
}

.contacts__title {
	margin-bottom: 5px;
}

.contacts__address, .contacts__phone {
	font-size: 1.11111rem;
}

.contacts__address {
	margin-bottom: 2px;
}

@media screen and (max-width: 1199px) {
	.contacts__address {
		margin-bottom: 10px;
	}
}

.contacts__phone {
	display: block;
	margin-bottom: 90px;
	color: #000;
}

@media screen and (max-width: 1199px) {
	.contacts__phone {
		margin-bottom: 50px;
	}
}

@media screen and (max-width: 991px) {
	.contacts__phone {
		margin-bottom: 30px;
	}
}

.contacts__phone:hover {
	color: #ff8329;
}

.contacts__map {
	width: 560px;
	height: 460px;
}

@media screen and (max-width: 1199px) {
	.contacts__map {
		width: 486px;
		height: 400px;
	}
}

@media screen and (max-width: 991px) {
	.contacts__map {
		width: 546px;
		height: 460px;
		max-width: 100%;
		max-height: 100%;
	}
}

@media screen and (max-width: 575px) {
	.contacts__map {
		top: 0;
		left: 0;
		position: absolute;
	}
}

.contacts-schedule {
	display: flex;
	flex-direction: column;
}

.contacts-schedule__title {
	font-weight: 700;
	margin-bottom: 10px;
	font-size: 1.11111rem;
}

.contacts-schedule__row {
	display: flex;
	align-items: center;
}

.contacts-schedule__row span:first-child {
	width: 60px;
	margin-right: 30px;
}

.contacts-schedule__row:not(:last-child) {
	margin-bottom: 5px;
}

.footer {
	padding: 30px 0;
	border-top: 1px solid #d7d7d7;
}

.footer__wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media screen and (max-width: 991px) {
	.footer__wrapper {
		flex-direction: column;
		align-items: flex-start;
	}
}

.footer__block {
	display: flex;
	flex-direction: column;
}

.footer__block_last {
	align-items: flex-end;
}

@media screen and (max-width: 991px) {
	.footer__block_last {
		align-items: flex-start;
	}
}

.footer__link {
	font-weight: 500;
	font-size: 0.77778rem;
	color: #000;
	text-decoration: underline;
}

.footer__link:hover {
	color: #ff8329;
	text-decoration: underline;
}

.footer__link:not(:last-child) {
	margin-bottom: 10px;
}

@media screen and (max-width: 991px) {
	.footer .logo {
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 991px) {
	.preview {
		padding: 15px 0 43px;
		background-color: #f3f3f3;
	}
}

.preview__wrapper {
	display: flex;
	align-items: flex-start;
	padding: 36px 40px 32px 60px;
	background-color: #f3f3f3;
}

@media screen and (max-width: 1199px) {
	.preview__wrapper {
		padding: 30px 27px 27px 34px;
	}
}

@media screen and (max-width: 991px) {
	.preview__wrapper {
		padding: 0;
		flex-direction: column-reverse;
	}
}

.preview__content {
	margin-right: 32px;
}

@media screen and (max-width: 991px) {
	.preview__content {
		margin-right: 0;
	}
}

.preview__title {
	line-height: 1;
	font-weight: 700;
	margin-top: 15px;
	position: relative;
	margin-bottom: 12px;
	font-size: 3.83333rem;
	padding-bottom: 25px;
}

@media screen and (max-width: 1199px) {
	.preview__title {
		font-size: 2.77778rem;
	}
}

@media screen and (max-width: 991px) {
	.preview__title {
		margin-top: 25px;
		font-size: 2.22222rem;
	}
}

.preview__title:after {
	left: 0;
	bottom: 0;
	height: 6px;
	content: '';
	width: 211px;
	display: block;
	position: absolute;
	background-color: #ff8329;
}

.preview__image {
	width: 100%;
	max-width: 470px;
}

@media screen and (max-width: 1199px) {
	.preview__image {
		max-width: 420px;
	}
}

.preview-socials {
	display: flex;
	margin-top: 20px;
	align-items: center;
}

@media screen and (max-width: 1199px) {
	.preview-socials {
		margin-top: 34px;
	}
}

.preview-socials__link {
	width: 39px;
	height: 39px;
	display: block;
}

.preview-socials__link:not(:last-child) {
	margin-right: 26px;
}

.preview-socials__link svg {
	width: 100%;
	height: 100%;
}

.breadcrumbs {
	padding: 15px 0 36px;
}

@media screen and (max-width: 991px) {
	.breadcrumbs {
		display: none;
	}
}

.breadcrumbs__items {
	display: flex;
	align-items: center;
}

.breadcrumbs a.breadcrumbs__item:hover {
	color: #ff8329;
}

.breadcrumbs__item {
	color: #000;
	font-size: 0.83333rem;
}

.breadcrumbs__item:not(:last-child):after {
	content: '—';
	margin: 0 5px;
	color: #000 !important;
}

.breadcrumbs__item_active {
	color: #999;
}

.maintenance {
	padding: 36px 0;
}

@media screen and (max-width: 1199px) {
	.maintenance {
		padding: 43px 0 21px;
	}
}

@media screen and (max-width: 991px) {
	.maintenance {
		padding: 30px 0 20px;
	}
}

.maintenance__title {
	margin-bottom: 15px;
}

@media screen and (max-width: 991px) {
	.maintenance__title {
		margin-bottom: 10px;
	}
}

.maintenance__items {
	display: flex;
	flex-direction: column;
}

.maintenance__item {
	color: #0057FF;
	display: block;
	padding: 15px 0;
	font-size: 1.11111rem;
	border-bottom: 1px solid #e5e5e5;
}

@media screen and (max-width: 991px) {
	.maintenance__item {
		padding: 12px 0;
		font-size: 0.88889rem;
	}
}

.maintenance__item:first-child {
	border-top: 1px solid #e5e5e5;
}

.maintenance__item:hover {
	color: #ff8329;
}

.slider {
	padding: 48px 0;
}

@media screen and (max-width: 1199px) {
	.slider {
		padding: 40px 0;
	}
}

@media screen and (max-width: 991px) {
	.slider {
		padding: 20px 0 30px;
	}
}

.slider__image {
	width: 100%;
}

.slider__label {
	color: #9c9c9c;
	margin-top: 13px;
	font-size: 0.77778rem;
}

.slider-nav {
	display: flex;
	margin-top: 37px;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 991px) {
	.slider-nav {
		padding: 0 15px;
		margin-top: 28px;
		justify-content: space-between;
	}
}

.slider-nav__button {
	cursor: pointer;
	padding: 17px 24px;
	border: 2px solid #c4c4c4;
	background-color: transparent;
}

.slider-nav__num {
	width: 60px;
	display: flex;
	margin: 0 15px;
	align-items: center;
	justify-content: center;
}

.slider-nav__num-separator {
	margin: 0 5px;
}

.slider .swiper-slide {
	opacity: .2;
	max-width: 1140px;
	transform: scale(0.9);
	transition: transform .3s, opacity .3s;
}

@media screen and (max-width: 1199px) {
	.slider .swiper-slide {
		padding: 0 20px;
	}
}

@media screen and (max-width: 991px) {
	.slider .swiper-slide {
		padding: 0 15px;
	}
}

.slider .swiper-slide-active {
	opacity: 1;
	transform: scale(1);
}

.info {
	padding: 36px 0 48px;
}

@media screen and (max-width: 1199px) {
	.info {
		padding: 21px 0 40px;
	}
}

@media screen and (max-width: 991px) {
	.info {
		padding: 20px 0;
	}
}

.info_card {
	padding: 0 !important;
}

@media screen and (max-width: 991px) {
	.info_card .container {
		padding: 0 !important;
	}
}

.info_section {
	margin: 48px 0 34px;
	padding: 56px 0 85px;
	border-top: 1px solid #E5E5E5;
	border-bottom: 1px solid #E5E5E5;
}

@media screen and (max-width: 1199px) {
	.info_section {
		margin: 37px 0 25px;
		padding: 54px 0 70px;
	}
}

@media screen and (max-width: 991px) {
	.info_section {
		margin: 30px 0;
		padding: 42px 0;
	}
}

.info__grid {
	display: grid;
	grid-gap: 40px;
}

.info__grid_two {
	grid-template-columns: repeat(2, 1fr);
}

.info__grid_two p {
	margin-bottom: 18px;
}

@media screen and (max-width: 991px) {
	.info__grid_two p {
		margin-bottom: 0;
		font-size: 0.88889rem;
	}
}

.info__grid_two-fs {
	grid-template-columns: 1fr 1.5fr;
}

.info__grid_two-ls {
	grid-template-columns: 1.5fr 1fr;
}

@media screen and (max-width: 991px) {
	.info__grid_two {
		grid-template-columns: repeat(1, 1fr);
	}
}

.info__grid_three {
	grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 991px) {
	.info__grid_three {
		grid-template-columns: repeat(1, 1fr);
	}
}

.info__grid_three .info__subtitle {
	height: auto !important;
}

.info__cell {
	display: flex;
	flex-direction: column;
}

.info__title {
	line-height: 1;
	position: relative;
	margin-bottom: 20px;
}

.info__title_underline {
	padding-bottom: 30px;
}

.info__title_underline:before {
	left: 0;
	bottom: 0;
	height: 6px;
	content: '';
	width: 211px;
	display: block;
	position: absolute;
	background-color: #ff8329;
}

.info__subtitle {
	height: 50px;
	line-height: 1;
	font-weight: 700;
	margin-bottom: 15px;
	font-size: 1.38889rem;
}

@media screen and (max-width: 991px) {
	.info__subtitle {
		height: auto;
		font-size: 1.11111rem;
	}
}

.info p {
	font-size: 1.11111rem;
}

@media screen and (max-width: 991px) {
	.info p {
		font-size: 0.88889rem;
	}
}

.info p.mb60 {
	margin-bottom: 60px;
}

.info p.mb20 {
	margin-bottom: 20px;
}

.info p.boundary {
	max-width: 750px;
}

@media screen and (max-width: 991px) {
	.info p.boundary {
		max-width: unset;
	}
}

.info__explanation {
	color: #9c9c9c;
	font-size: 0.77778rem;
}

.info__image {
	width: 100%;
	max-width: 100%;
	margin: 50px 0 0;
}

.info ul {
	display: flex;
	flex-direction: column;
}

.info ul li {
	position: relative;
	padding-left: 14px;
}

.info ul li:before {
	width: 6px;
	height: 6px;
	left: 0;
	top: 12px;
	content: '';
	display: block;
	position: absolute;
	background-color: #ff8329;
}

.info ul li:not(:last-of-type) {
	margin-bottom: 25px;
}

.info-reference {
	display: flex;
}

.info-reference_center {
	justify-content: center;
}

.info-reference__inner {
	display: flex;
	max-width: 267px;
	text-align: center;
	flex-direction: column;
}

.info-reference__num {
	line-height: 1;
	margin-bottom: 8px;
	font-size: 5.55556rem;
	color: #0053f7;
}

.info-reference p {
	color: #999;
	font-size: 1.11111rem;
}

.info-blocks__items {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 991px) {
	.info-blocks__items {
		grid-template-columns: 1fr;
	}
}

.info-blocks__item {
	display: flex;
	min-height: 480px;
	position: relative;
	background-size: cover;
	background-position: center;
}

@media screen and (max-width: 1199px) {
	.info-blocks__item {
		min-height: 403px;
	}
}

@media screen and (max-width: 991px) {
	.info-blocks__item {
		min-height: 220px;
	}
}

.info-blocks__item_video .info-blocks__title {
	font-size: 1.11111rem;
}

.info-blocks__overlay {
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(0, 87, 255, 0.8);
}

.info-blocks__title {
	z-index: 2;
	left: 38px;
	color: #fff;
	bottom: 38px;
	display: flex;
	line-height: 1;
	font-weight: 700;
	max-width: 350px;
	position: absolute;
	align-items: center;
	font-size: 1.66667rem;
}

@media screen and (max-width: 991px) {
	.info-blocks__title {
		left: 22px;
		bottom: 36px;
		font-size: 1.11111rem !important;
	}
}

.info-blocks__title svg {
	margin-right: 22px;
	width: 43px;
	height: 43px;
}

.info-card {
	display: flex;
	background: #fff;
	margin: 39px 0 34px;
	padding: 49px 31px 64px 54px;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 1199px) {
	.info-card {
		margin: 15px 0 43px;
	}
}

@media screen and (max-width: 991px) {
	.info-card {
		margin: 15px 0 25px;
		padding: 33px 15px 38px;
	}
}

.info-card__block_first {
	width: 100%;
	display: grid;
	flex: 0 1 100%;
	grid-row-gap: 30px;
	grid-column-gap: 20px;
	grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 767px) {
	.info-card__block_first {
		grid-row-gap: 20px;
		grid-template-columns: 1fr;
	}
}

.info-card__block_last {
	width: 198px;
	display: flex;
	flex: 0 0 198px;
	margin: 0 0 0 38px;
	position: relative;
}

@media screen and (max-width: 991px) {
	.info-card__block_last {
		display: none;
	}
}

.info-card__block_last:before {
	top: -15px;
	z-index: 1;
	left: 30px;
	right: 30px;
	content: '';
	bottom: -15px;
	background: #fff;
	position: absolute;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

.info-card__cell {
	grid-column-start: span 1;
}

.info-card__cell_big {
	grid-column-start: span 3;
}

@media screen and (max-width: 767px) {
	.info-card__cell_big {
		grid-column-start: span 1;
	}
}

.info-card__title {
	font-weight: 700;
	margin-bottom: 10px;
	font-size: 1.11111rem;
}

.info-card__title ~ p {
	font-size: 0.88889rem;
}

.info-card__subtitle {
	font-weight: 700;
	font-size: 1.11111rem;
}

@media screen and (max-width: 991px) {
	.info-card__subtitle {
		font-size: 0.88889rem;
	}
}

.info-card__subtitle ~ p {
	font-size: 0.77778rem;
}

.info-card__icon {
	z-index: 2;
	display: block;
	background: #fff;
	align-self: center;
	padding: 68px 68px;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

.info-card__icon-image {
	width: 62px;
	height: 62px;
}

.table-section {
	padding: 36px 0 70px;
}

@media screen and (max-width: 1199px) {
	.table-section {
		padding: 41px 0 51px;
	}
}

@media screen and (max-width: 991px) {
	.table-section {
		padding: 23px 0 27px;
	}
}

@media screen and (max-width: 991px) {
	.table-section__inner_desktop {
		display: none;
	}
}

.table-section__inner_mobile {
	display: none;
}

@media screen and (max-width: 991px) {
	.table-section__inner_mobile {
		width: 100%;
		display: block;
	}
}

table {
	width: 100%;
	border-collapse: collapse;
}

@media screen and (max-width: 991px) {
	table {
		border-top: 2px solid #0057FF;
	}
	table:last-child {
		border-bottom: 2px solid #0057FF;
	}
	table th, table td {
		width: 50% !important;
	}
}

table tr {
	border-bottom: 1px solid #CDD1DD;
}

@media screen and (max-width: 991px) {
	table tr:nth-child(2n) {
		background-color: #ECEFF7;
	}
}

table tr:first-of-type {
	border-bottom: 2px solid #0057FF;
}

@media screen and (max-width: 991px) {
	table tr:first-of-type {
		border-bottom: 1px solid #CDD1DD;
	}
}

table tr:first-of-type > th {
	text-align: left;
	line-height: 1.1;
	font-weight: 700;
	font-size: 1rem;
}

@media screen and (max-width: 991px) {
	table tr:first-of-type > th {
		font-size: 0.77778rem;
	}
}

@media screen and (max-width: 991px) {
	table th:first-of-type {
		text-align: left;
	}
}

table th, table td {
	padding: 20px;
}

@media screen and (max-width: 991px) {
	table th, table td {
		padding: 5px 15px;
		font-size: 0.77778rem;
	}
}

table th:nth-child(2n), table td:nth-child(2n) {
	background-color: #ECEFF7;
}

@media screen and (max-width: 991px) {
	table th:nth-child(2n), table td:nth-child(2n) {
		background-color: #fff;
	}
}

table a {
	color: #2D55D2;
}

table a:hover {
	color: #ff8329;
}

.article {
	padding: 69px 0 46px;
}

@media screen and (max-width: 1199px) {
	.article {
		padding: 40px 0 25px;
	}
}

@media screen and (max-width: 991px) {
	.article {
		display: none;
	}
}

.article__wrapper {
	display: flex;
	padding: 53px 0 58px;
	justify-content: center;
	background-color: #fff;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

.article__footnote {
	top: 5px;
	left: 26px;
	line-height: 1;
	position: absolute;
	font-size: 5.44444rem;
	color: #0053f7;
}

.article__inner {
	z-index: 5;
	display: block;
	max-width: 1070px;
	padding: 40px 90px;
	position: relative;
	background-color: #fff;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

.article__inner:before {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	content: '';
	display: block;
	position: absolute;
	background-color: #fff;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

.article__inner:after {
	left: 40px;
	top: -23px;
	right: 40px;
	z-index: -1;
	content: '';
	opacity: 0.5;
	bottom: -23px;
	display: block;
	position: absolute;
	background-color: #fff;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

.article__label, .article__footnote {
	z-index: 6;
	display: block;
}

.article__label {
	position: relative;
	font-size: 1.66667rem;
}

.fancybox-menu .fancybox-slide {
	padding: 0;
}

.fancybox-menu .fancybox-content {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 98px 30px 32px;
}

.fancybox-menu .fancybox-button {
	width: 30px;
	height: 30px;
	top: 40px;
	left: 23px;
	padding: 0;
}

.fancybox-menu .popup-menu {
	width: 100%;
}

.fancybox-menu .popup-menu__phone {
	line-height: 1;
	font-weight: 700;
	padding-top: 16px;
	font-size: 0.83333rem;
	color: #000;
}

.fancybox-menu .popup-menu__back {
	border: none;
	font-weight: 700;
	line-height: 21px;
	position: relative;
	font-size: 1rem;
	padding: 0 0 0 35px;
	margin-bottom: 40px;
	background-color: transparent;
}

.fancybox-menu .popup-menu__back:before {
	width: 30px;
	height: 30px;
	left: 0;
	top: 50%;
	content: '';
	position: absolute;
	background-size: cover;
	transform: translateY(-50%);
	background-position: center;
	background-image: url("../img/common/arrow-back-700.png");
}

.fancybox-menu .popup-menu__wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	height: calc(100vh - 130px);
	justify-content: space-between;
}

.fancybox-menu .popup-menu__pages {
	width: 100%;
	overflow: hidden;
}

.fancybox-menu .popup-menu__slice {
	height: 100%;
	display: flex;
	width: calc(100vw - 60px);
	transition: transform .35s ease-in-out;
}

.fancybox-menu .popup-menu__page {
	height: 100%;
	overflow-y: scroll;
	flex: 0 0 calc(100vw - 60px);
}

.fancybox-menu .popup-nav__list {
	display: flex;
	flex-direction: column;
}

.fancybox-menu .popup-nav__item {
	border-top: 1px solid #cdcdcd;
}

.fancybox-menu .popup-nav__item_dropdown {
	position: relative;
}

.fancybox-menu .popup-nav__item_dropdown:before {
	width: 20px;
	height: 20px;
	top: 50%;
	right: 0;
	content: '';
	position: absolute;
	transform: translateY(-50%);
	background-image: url("../img/common/arrow-right.png");
}

.fancybox-menu .popup-nav__link {
	display: block;
	padding: 12px 0;
	font-weight: 700;
	line-height: 21px;
	font-size: 1rem;
	color: #000;
}

.error__img {
	text-align: center;
}

.error__img img {
	max-width: 100%;
	height: auto;
}

.error__caption {
	color: #e0e0e0;
	font-weight: 900;
	font-size: 100px;
	line-height: 0.75;
	position: relative;
}

.error__title {
	color: #e0e0e0;
	font-size: 62px;
	font-weight: 900;
	line-height: 1em;
}

.error__intro {
	color: #6b6b6b;
	font-size: 28px;
	font-weight: 400;
	line-height: 1.2;
	margin: 1em 0;
}

.error__intro a {
	color: #ff8329;
}

@media (min-width: 768px) {
	.error__intro {
		max-width: 60%;
	}
	.error__caption {
		margin-top: -80px;
	}
}

@media (min-width: 992px) {
	.error__caption {
		margin-top: -170px;
	}
}

@media (min-width: 1200px) {
	.error__caption {
		margin-top: -190px;
	}
}
