// Default
$base-font-size: 18px;
$base-min-width: 360px;
$base-line-height: 1.5;

// Font
$primary-font-family: 'Raleway';
$secondary-font-family: 'Proxima Nova';
$secondary-cond-font-family: 'Proxima Nova Condensed';

// Responsive size
$responsive-xl: 1199px;
$responsive-lg: 991px;
$responsive-md: 767px;
$responsive-sm: 575px;

// Container size
$width-container: 1180px;

// Container padding
$padding-container: 20px;
$padding-container-md: 15px;

// Color
$primary-color: #ff8329;
$secondary-color: #0053f7;

$primary-text: #000;
$secondary-text: #6b7b8e;

$primary-background: #fff;
$secondary-background: #f3f3f3;