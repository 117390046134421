.table {
  &-section {
    padding: 36px 0 70px;
    @include respond-below($responsive-xl) {
      padding: 41px 0 51px;
    }
    @include respond-below($responsive-lg) {
      padding: 23px 0 27px;
    }
    &__inner {
      &_desktop {
        @include respond-below($responsive-lg) {
          display: none;
        }
      }
      &_mobile {
        display: none;
        @include respond-below($responsive-lg) {
          width: 100%;
          display: block;
        }
      }
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  @include respond-below($responsive-lg) {
    border-top: 2px solid #0057FF;
    &:last-child {
      border-bottom: 2px solid #0057FF;
    }
    th, td {
      width: 50% !important;
    }
  }
  tr {
    border-bottom: 1px solid #CDD1DD;
    @include each(2) {
      @include respond-below($responsive-lg) {
        background-color: #ECEFF7;
      }
    }
    &:first-of-type {
      border-bottom: 2px solid #0057FF;
      @include respond-below($responsive-lg) {
        border-bottom: 1px solid #CDD1DD;
      }
      & > th {
        text-align: left;
        line-height: 1.1;
        font-weight: 700;
        font-size: rem(18px);
        @include respond-below($responsive-lg) {
          font-size: rem(14px);
        }
      }
    }
  }
  th {
    &:first-of-type {
      @include respond-below($responsive-lg) {
        text-align: left;
      }
    }
  }
  th, td {
    padding: 20px;
    @include respond-below($responsive-lg) {
      padding: 5px 15px;
      font-size: rem(14px);
    }
    @include each(2) {
      background-color: #ECEFF7;
      @include respond-below($responsive-lg) {
        background-color: $primary-background;
      }
    }
  }
  a {
    color: #2D55D2;
    &:hover {
      color: $primary-color;
    }
  }
}