.cards {
  padding: 64px 0 92px;
  @include respond-below($responsive-xl) {
    padding: 50px 0 75px;
  }
  @include respond-below($responsive-lg) {
    padding: 24px 0 25px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__title {
    margin-bottom: 50px;
    @include respond-below($responsive-xl) {
      margin-bottom: 22px;
    }
    @include respond-below($responsive-lg) {
      line-height: 1.5;
      margin-bottom: 5px;
    }
  }
  &-list {
    display: grid;
    grid-row-gap: 58px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    @include respond-below($responsive-xl) {
      grid-row-gap: 50px;
    }
    @include respond-below($responsive-lg) {
      grid-row-gap: 30px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &-item {
    color: $primary-text;
    &__preview {
      width: 100%;
      height: 193px;
      border-radius: 5px;
      margin-bottom: 10px;
      background-size: cover;
      background-position: center;
    }
    &__title {
      font-size: rem(30px);
      transition: color .3s ease-in-out;
      @include respond-below($responsive-xl) {
        font-size: rem(26px);
      }
      @include respond-below($responsive-lg) {
        font-size: rem(22px);
      }
    }
    &__desc {
      margin-top: 3px;
      font-size: rem(14px);
    }
  }
}