.footer {
  padding: 30px 0;
  border-top: 1px solid #d7d7d7;
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond-below($responsive-lg) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    &_last {
      align-items: flex-end;
      @include respond-below($responsive-lg) {
        align-items: flex-start;
      }
    }
  }
  &__link {
    font-weight: 500;
    font-size: rem(14px);
    color: $primary-text;
    text-decoration: underline;
    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  .logo {
    @include respond-below($responsive-lg) {
      margin-bottom: 20px;
    }
  }
}