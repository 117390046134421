.error {
  &__img {
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__caption {
    color: #e0e0e0;
    font-weight: 900;
    font-size: 100px;
    line-height: 0.75;
    position: relative;
  }

  &__title {
    color: #e0e0e0;
    font-size: 62px;
    font-weight: 900;
    line-height: 1em;
  }

  &__intro {
    color: #6b6b6b;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.2;
    margin: 1em 0;

    a {
      color: #ff8329;
    }
  }
}

@media (min-width: 768px) {
  .error {
    &__intro {
      max-width: 60%;
    }

    &__caption {
      margin-top: -80px;
    }
  }
}

@media (min-width: 992px) {
  .error__caption {
    margin-top: -170px;
  }
}

@media (min-width: 1200px) {
  .error__caption {
    margin-top: -190px;
  }
}
