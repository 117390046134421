.preview {
  @include respond-below($responsive-lg) {
    padding: 15px 0 43px;
    background-color: $secondary-background;
  }
  &__wrapper {
    display: flex;
    align-items: flex-start;
    padding: 36px 40px 32px 60px;
    background-color: $secondary-background;
    @include respond-below($responsive-xl) {
      padding: 30px 27px 27px 34px;
    }
    @include respond-below($responsive-lg) {
      padding: 0;
      flex-direction: column-reverse;
    }
  }
  &__content {
    margin-right: 32px;
    @include respond-below($responsive-lg) {
      margin-right: 0;
    }
  }
  &__title {
    line-height: 1;
    font-weight: 700;
    margin-top: 15px;
    position: relative;
    margin-bottom: 12px;
    font-size: rem(69px);
    padding-bottom: 25px;
    @include respond-below($responsive-xl) {
      font-size: rem(50px);
    }
    @include respond-below($responsive-lg) {
      margin-top: 25px;
      font-size: rem(40px);
    }
    &:after {
      left: 0;
      bottom: 0;
      height: 6px;
      content: '';
      width: 211px;
      display: block;
      position: absolute;
      background-color: $primary-color;
    }
  }
  &__image {
    width: 100%;
    max-width: 470px;
    @include respond-below($responsive-xl) {
      max-width: 420px;
    }
  }
  &-socials {
    display: flex;
    margin-top: 20px;
    align-items: center;
    @include respond-below($responsive-xl) {
      margin-top: 34px;
    }
    &__link {
      @include size(39px);
      display: block;
      &:not(:last-child) {
        margin-right: 26px;
      }
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}