@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    @if $asset-pipeline == true {
      src: font-url('#{$file-path}.eot');
      src: font-url('#{$file-path}.eot?#iefix') format('embedded-opentype'), font-url('#{$file-path}.woff') format('woff'), font-url('#{$file-path}.ttf') format('truetype');
    } @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype');
    }
  }
}

@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}


@mixin respond-below($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}


@mixin respond-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin size($a,$b: $a) {
  width: $a;
  height: $b;
}

@mixin placeholder {
  ::-webkit-input-placeholder { @content }
  :-moz-placeholder { @content }
  ::-moz-placeholder { @content }
  :-ms-input-placeholder { @content }
}

@mixin first($num) {
  @if $num == 1 {
    &:first-child {
      @content;
    }
  } @else {
    &:nth-child(-n + #{$num}) {
      @content;
    }
  }
}

@mixin last($num) {
  &:nth-last-child(-n + #{$num}) {
    @content;
  }
}

@mixin after-first($num) {
  &:nth-child(n + #{$num + 1}) {
    @content;
  }
}

@mixin from-end($num) {
  &:nth-last-child(#{$num}) {
    @content;
  }
}

@mixin between($first, $last) {
  &:nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}

@mixin even-between($first, $last) {
  &:nth-child(even):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}

@mixin odd-between($first, $last) {
  &:nth-child(odd):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}

@mixin n-between($num, $first, $last) {
  &:nth-child(#{$num}n):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}

@mixin all-but($num) {
  &:not(:nth-child(#{$num})) {
    @content;
  }
}

@mixin each($num) {
  &:nth-child(#{$num}n) {
    @content;
  }
}

@mixin every($num) {
  &:nth-child(#{$num}n) {
    @content;
  }
}

@mixin from-first-last($num) {
  &:nth-child(#{$num}),
  &:nth-last-child(#{$num}) {
    @content;
  }
}

@mixin middle($num) {
  &:nth-child(#{round($num / 2)}) {
    @content;
  }
}

@mixin all-but-first-last($num) {
  &:nth-child(n + #{$num}):nth-last-child(n + #{$num}) {
    @content;
  }
}

@mixin first-of($limit) {
  &:nth-last-child(#{$limit}):first-child {
    @content;
  }
}

@mixin last-of($limit) {
  &:nth-of-type(#{$limit}):nth-last-of-type(1) {
    @content;
  }
}

@mixin at-least($num) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);

  &:nth-last-child(n + #{$num}),
  &:nth-last-child(n + #{$num}) ~ #{$child} {
    @content;
  }
}

@mixin at-most($num) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);

  &:nth-last-child(-n + #{$num}):first-child,
  &:nth-last-child(-n + #{$num}):first-child ~ #{$child} {
    @content;
  }
}

@mixin in-between($min, $max) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);

  &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child,
  &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child ~ #{$child} {
    @content;
  }
}

@mixin first-child() {
  &:first-of-type {
    @content
  }
}

@mixin last-child() {
  &:last-of-type {
    @content
  }
}

@mixin even() {
  &:nth-child(even) {
    @content;
  }
}

@mixin odd() {
  &:nth-child(odd) {
    @content;
  }
}

@mixin first-last() {
  &:first-child,
  &:last-child {
    @content;
  }
}

@mixin unique() {
  &:only-child {
    @content;
  }
}

@mixin only() {
  &:only-child {
    @content;
  }
}

@mixin not-unique() {
  &:not(:only-child) {
    @content;
  }
}

@mixin child-index($num, $direction: 'forward', $index: 0) {
  @for $i from 1 through $num {
    @if ($direction == 'forward') {
      &:nth-child(#{$i}) {
        z-index: order-index($i, $index);
        @content;
      }
    } @else if ($direction == 'backward') {
      &:nth-last-child(#{$i}) {
        z-index: order-index($i, $index);
        @content;
      }
    }
  }
}

@function order-index($i, $index) {
  @return ($index + $i);
}