.catalog {
  padding: 64px 0 59px;
  @include respond-below($responsive-xl) {
    padding: 53px 0 52px;
  }
  @include respond-below($responsive-lg) {
    padding: 24px 0;
  }
  &__wrapper {
    z-index: 6;
    display: flex;
    margin-left: 33px;
    position: relative;
    padding: 26px 64px;
    align-items: center;
    @include respond-below($responsive-lg) {
      margin-left: 0;
      flex-direction: column;
      padding: 30px 20px 40px;
    }
    &:before {
      z-index: 1;
      top: -44px;
      left: -33px;
      content: '';
      right: 290px;
      bottom: -44px;
      display: block;
      position: absolute;
      background-color: $primary-background;
      box-shadow: 0 0 50px rgba(0, 0, 0, .12);
      @include respond-below($responsive-lg) {
        content: unset;
      }
    }
    &:after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      content: '';
      display: block;
      position: absolute;
      background-color: $primary-background;
      box-shadow: 0 0 50px rgba(0, 0, 0, .12);
    }
  }
  &__block {
    z-index: 9;
    display: block;
    position: relative;
    &_first {
      max-width: 628px;
      @include respond-below($responsive-lg) {
        margin-bottom: 25px;
      }
    }
    &_last {
      margin-left: 80px;
      @include respond-below($responsive-lg) {
        margin-left: 0;
      }
      &:before {
        z-index: 2;
        top: -40px;
        content: '';
        left: -64px;
        right: -40px;
        bottom: -40px;
        opacity: 0.56;
        display: block;
        position: absolute;
        background-color: $primary-background;
        box-shadow: 0 0 50px rgba(0, 0, 0, .12);
        @include respond-below($responsive-lg) {
          content: unset;
        }
      }
    }
  }
  &__title {
    margin-bottom: 8px;
    @include respond-below($responsive-lg) {
      text-align: center;
    }
  }
  &__desc {
    font-size: rem(16px);
    @include respond-below($responsive-lg) {
      text-align: center;
    }
  }
  &__button {
    @include size(198px);
    z-index: 3;
    color: #fff;
    display: flex;
    font-weight: 700;
    border-radius: 50%;
    position: relative;
    text-align: center;
    align-items: center;
    font-size: rem(20px);
    justify-content: center;
    background-color: $secondary-color;
    transition: opacity .3s ease-in-out;
    &:hover {
      opacity: .9;
    }
  }
}