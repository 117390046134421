.faqs {
  padding: 80px 0 55px;
  @include respond-below($responsive-xl) {
    padding: 55px 0 28px;
  }
  @include respond-below($responsive-lg) {
    padding: 33px 0 18px;
  }
  &__title {
    margin-bottom: 18px;
    @include respond-below($responsive-lg) {
      margin-bottom: 33px;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
  }
  &__item {
    border-radius: 4px;
    border-left: 9px solid $primary-color;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, .07);
    @include respond-below($responsive-xl) {
      border-width: 8px;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
      @include respond-below($responsive-lg) {
        margin-bottom: 10px;
      }
    }
    &_active {
      .faqs__header {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }
  &__header {
    line-height: 1;
    cursor: pointer;
    font-weight: 700;
    padding: 32px 30px;
    position: relative;
    font-size: rem(29px);
    &:after {
      top: 50%;
      width: 8px;
      height: 5px;
      right: 23px;
      content: '';
      display: block;
      position: absolute;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(-50%) rotate(0);
      transition: transform .3s ease-in-out;
      background-image: url("../img/faqs/carrot.png");
    }
    @include respond-below($responsive-xl) {
      padding: 25px 40px;
      font-size: rem(26px);
    }
    @include respond-below($responsive-lg) {
      font-size: rem(18px);
      padding: 15px 20px;
    }
  }
  &__content {
    padding: 40px 30px 30px;
    border-top: 1px solid $secondary-background;
    @include respond-below($responsive-lg) {
      padding: 17px 13px 30px;
    }
    ul {
      margin-bottom: 25px;
      font-size: rem(23px);
      @include respond-below($responsive-lg) {
        margin-bottom: 20px;
        font-size: rem(16px);
      }
      li {
        display: flex;
        position: relative;
        align-items: flex-start;
        &:before {
          @include size(6px);
          content: '';
          margin-top: 14px;
          margin-right: 8px;
          position: relative;
          background-color: $primary-color;
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
    ol {
      display: flex;
      flex-direction: column;
      li {
        display: flex;
        line-height: 1.5;
        align-items: center;
        font-size: rem(23px);
        &:not(:last-child) {
          margin-bottom: 50px;
          @include respond-below($responsive-lg) {
            margin-bottom: 20px;
            font-size: rem(16px);
          }
        }
      }
      &__num {
        width: 23px;
        margin-right: 20px;
        font-size: rem(16px);
        color: $secondary-color;
        @include respond-below($responsive-lg) {
          margin-right: 5px;
          text-align: center;
          font-size: rem(12px);
        }
      }
    }
    & > p {
      line-height: 1.5;
      font-size: rem(23px);
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      @include respond-below($responsive-lg) {
        font-size: rem(16px);
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
  &__footer {
    margin-top: 50px;
    @include respond-below($responsive-lg) {
      margin-top: 26px;
    }
  }
  &__button {
    min-width: 310px;
    @include respond-below($responsive-lg) {
      width: 100%;
    }
  }
}