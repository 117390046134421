.fancybox-menu {
  .fancybox {
    &-slide {
      padding: 0;
    }
    &-content {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      padding: 98px 30px 32px;
    }
    &-button {
      @include size(30px);
      top: 40px;
      left: 23px;
      padding: 0;
    }
  }
  .popup-menu {
    width: 100%;
    &__phone {
      line-height: 1;
      font-weight: 700;
      padding-top: 16px;
      font-size: rem(15px);
      color: $primary-text;
    }
    &__back {
      border: none;
      font-weight: 700;
      line-height: 21px;
      position: relative;
      font-size: rem(18px);
      padding: 0 0 0 35px;
      margin-bottom: 40px;
      background-color: transparent;
      &:before {
        @include size(30px);
        left: 0;
        top: 50%;
        content: '';
        position: absolute;
        background-size: cover;
        transform: translateY(-50%);
        background-position: center;
        background-image: url('../img/common/arrow-back-700.png');
      }
    }
    &__wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: calc(100vh - 130px);
      justify-content: space-between;
    }
    &__pages {
      width: 100%;
      overflow: hidden;
    }
    &__slice {
      height: 100%;
      display: flex;
      width: calc(100vw - 60px);
      transition: transform .35s ease-in-out;
    }
    &__page {
      height: 100%;
      overflow-y: scroll;
      flex: 0 0 calc(100vw - 60px);
    }
  }
  .popup-nav {
    &__list {
      display: flex;
      flex-direction: column;
    }
    &__item {
      border-top: 1px solid #cdcdcd;
      &_dropdown {
        position: relative;
        &:before {
          @include size(20px);
          top: 50%;
          right: 0;
          content: '';
          position: absolute;
          transform: translateY(-50%);
          background-image: url('../img/common/arrow-right.png');
        }
      }
    }
    &__link {
      display: block;
      padding: 12px 0;
      font-weight: 700;
      line-height: 21px;
      font-size: rem(18px);
      color: $primary-text;
    }
  }
}