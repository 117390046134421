.contacts {
  padding: 55px 0 80px;
  @include respond-below($responsive-xl) {
    padding: 28px 0 90px;
  }
  @include respond-below($responsive-lg) {
    padding: 18px 0 50px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @include respond-below($responsive-lg) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  &__block {
    &_first {
      margin-right: 80px;
      @include respond-below($responsive-lg) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
    &_last {
      @include respond-below($responsive-sm) {
        position: relative;
        padding-bottom: 85%;
      }
    }
  }
  &__title {
    margin-bottom: 5px;
  }
  &__address, &__phone {
    font-size: rem(20px);
  }
  &__address {
    margin-bottom: 2px;
    @include respond-below($responsive-xl) {
      margin-bottom: 10px;
    }
  }
  &__phone {
    display: block;
    margin-bottom: 90px;
    color: $primary-text;
    @include respond-below($responsive-xl) {
      margin-bottom: 50px;
    }
    @include respond-below($responsive-lg) {
      margin-bottom: 30px;
    }
    &:hover {
      color: $primary-color;
    }
  }
  &__map {
    width: 560px;
    height: 460px;
    @include respond-below($responsive-xl) {
      width: 486px;
      height: 400px;
    }
    @include respond-below($responsive-lg) {
      width: 546px;
      height: 460px;
      max-width: 100%;
      max-height: 100%;
    }
    @include respond-below($responsive-sm) {
      top: 0;
      left: 0;
      position: absolute;
    }
  }
  &-schedule {
    display: flex;
    flex-direction: column;
    &__title {
      font-weight: 700;
      margin-bottom: 10px;
      font-size: rem(20px);
    }
    &__row {
      display: flex;
      align-items: center;
      span {
        &:first-child {
          width: 60px;
          margin-right: 30px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
}