.section-form {
  padding: 80px 0 90px;
  background-color: $secondary-background;
  @include respond-below($responsive-xl) {
    padding: 35px 0 45px;
  }
  @include respond-below($responsive-lg) {
    padding: 40px 0 50px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  &__title {
    @include respond-below($responsive-lg) {
      margin-bottom: 10px;
    }
  }
  &__text {
    max-width: 80%;
    margin-bottom: 20px;
    font-size: rem(20px);
    @include respond-below($responsive-lg) {
      line-height: 1.2;
      max-width: unset;
      font-size: rem(18px);
    }
  }
  &__fields {
    display: flex;
    margin-bottom: 22px;
    align-items: flex-start;
    @include respond-below($responsive-lg) {
      flex-direction: column;
    }
  }
  &__item {
    @include respond-below($responsive-lg) {
      width: 100%;
    }
    &:not(:last-child) {
      margin-right: 15px;
      @include respond-below($responsive-lg) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
  &__input {
    padding: 20px;
    line-height: 1;
    min-width: 388px;
    font-size: rem(16px);
    border: 1px solid #e6e6e6;
    background-color: $primary-background;
    @include respond-below($responsive-xl) {
      min-width: 338px;
    }
    @include respond-below($responsive-lg) {
      width: 100%;
      min-width: auto;
    }
    @include placeholder() {
      opacity: .6;
      color: $primary-text;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    label.error {
      color: #fe334a;
      font-size: rem(12px);
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    @include respond-below($responsive-lg) {
      align-items: flex-start;
    }
    &-checkbox {
      margin-right: 7px;
      input {
        margin: 0;
      }
    }
    &-icon {
      display: none;
    }
    &-text {
      font-size: rem(13px);
      text-transform: uppercase;
      a {
        color: $primary-text;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}