.maintenance {
  padding: 36px 0;
  @include respond-below($responsive-xl) {
    padding: 43px 0 21px;
  }
  @include respond-below($responsive-lg) {
    padding: 30px 0 20px;
  }
  &__title {
    margin-bottom: 15px;
    @include respond-below($responsive-lg) {
      margin-bottom: 10px;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
  }
  &__item {
    color: #0057FF;
    display: block;
    padding: 15px 0;
    font-size: rem(20px);
    border-bottom: 1px solid #e5e5e5;
    @include respond-below($responsive-lg) {
      padding: 12px 0;
      font-size: rem(16px);
    }
    &:first-child {
      border-top: 1px solid #e5e5e5;
    }
    &:hover {
      color: $primary-color;
    }
  }
}