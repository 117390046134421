@charset "UTF-8";

@import "utils/vars";
@import "utils/mixins";
@import "utils/functions";
@import "utils/fonts";

html {
  cursor: default;
  font-weight: 400;
  letter-spacing: .01px;
  min-width: $base-min-width;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-family: $primary-font-family, Arial, sans-serif !important;
  font-variant-numeric: lining-nums;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: no-common-ligatures;
}
body {
  margin: 0;
  min-height: 100vh;
  color: $primary-text;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
}
ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
a, a:hover { text-decoration: none; }
a, .button { transition: color .3s ease-in-out; }
p { margin: 0; }
audio, canvas, iframe, img, svg, video, textarea { vertical-align: middle; }
textarea { resize: none; }
section { position: relative; }
input, select, button { outline: none; }
*, *:before, *:after { box-sizing: border-box; }

.wrapper {
  width: 100%;
  display: flex;
  margin: 0 auto;
  max-width: 1920px;
  min-height: 100vh;
  padding-top: 127px;
  flex-direction: column;
  background-color: $primary-background;
  box-shadow: 0 0 21px rgba(157, 163, 183, .75);
  @include respond-below($responsive-xl) {
    padding-top: 123px;
  }
  @include respond-below($responsive-lg) {
    padding-top: 93px;
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  max-width: $width-container;
  padding: 0 $padding-container;
  @include respond-below($responsive-lg) {
    padding: 0 $padding-container-md;
  }
  &_aside-right {
    display: grid;
    grid-gap: 0 50px;
    grid-template-columns: 3fr 1fr;
    @include respond-below($responsive-lg) { grid-template-columns: 1fr }
  }
}

.fancybox-privacy__content {
  max-width: 960px;
}

.title {
  font-weight: 700;
  font-size: rem(40px);
  @include respond-below($responsive-lg) {
    line-height: 1;
    font-size: rem(30px);
  }
}

.subtitle {
  font-weight: 700;
  font-size: rem(30px);
  @include respond-below($responsive-lg) {
    line-height: 1.2;
    font-size: rem(26px);
  }
}

.logo {
  display: flex;
  &.logo {
    &_header {
      .logo__link {
        height: 26px;
      }
    }
    &_footer {
      .logo__link {
        height: 34px;
      }
    }
  }
  &__link {
    display: flex;
  }
  &__image {
    height: 100%;
  }
}

.button {
  padding: 20px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  font-size: rem(16px);
  display: inline-block;
  border: 1px solid transparent;
  background-color: transparent;
  transition: all .3s ease-in-out;
  &_primary {
    color: #fff;
    border-color: $primary-color;
    background-color: $primary-color;
    &:hover {
      opacity: .9;
    }
  }
  &_outline {
    &.button_secondary {
      color: $primary-text;
      border-color: $secondary-color;
      background-color: $primary-background;
      &:hover {
        color: $primary-background;
        background-color: $secondary-color;
      }
    }
  }
}

@import "modules";
